@tailwind base;
@tailwind components;
@tailwind utilities;

html {
  scroll-behavior: smooth;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

article a {
  @apply text-sky-600;
}

article a:hover,
article a:focus {
  @apply text-sky-800;
}

button {
  @apply bg-slate-600;
  @apply rounded-full;
  @apply border-2;
  @apply shadow-xl;
}

button:hover {
  @apply bg-slate-800;
}
