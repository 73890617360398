#main-title--start {
  width: 90%;
  animation-name: expand;
  animation-duration: 1.5s;
  animation-fill-mode: forwards;
  margin: auto;
}

@keyframes expand {
  0% {
    width: 90%;
    opacity: 0;
  }
  100% {
    width: 100%;
    opacity: 1;
  }
}
